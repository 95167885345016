// Function component (stateful & container, using React, Redux & i18next hooks):

// React State hook.
import React, { useState } from 'react'
// Redux hook to extract data from the Redux store state.
import { useSelector } from 'react-redux'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { convertGToOz, convertOzToG, convertMlToFlOz, convertFlOzToMl } from '../../utils/numbers'
import { decimalCommaFormatter } from '../../utils/misc'
import { ncmParentMap } from '../../utils/nutrition'

import NutritionFacts from './NutritionFacts'

const NutritionFactsStatefulContainer = ({ food }) => {
	const [measure, setMeasure] = useState('proportion')
	const isAmount = measure === 'amount'
	const isCup = measure === 'cup'
	const isTbsp = measure === 'tbsp'
	const measureSplit = measure.split('-')
	const isPortion = measureSplit[0] === 'portion'
	const showServing = isCup || isTbsp || isPortion

	const inittialMass = 100
	const [mass, setMass] = useState(inittialMass)
	const isMass100 = mass === inittialMass

	const inittialMassInOz = convertGToOz(inittialMass)
	const [massInOz, setMassInOz] = useState(inittialMassInOz)

	const initialVolume = 100
	const [volume, setVolume] = useState(100)

	const initialVolumeInFlOz = convertMlToFlOz(initialVolume)
	const [volumeInFlOz, setVolumeInFlOz] = useState(initialVolumeInFlOz)

	const initialServing = 1
	const [serving, setServing] = useState(initialServing)

	const foodDensityValue = food?.foodDensity?.value
	const density = foodDensityValue && +foodDensityValue

	const d = density ?? 1

	const cupValueInMl = 240
	const cupValueInFlOz = 8 // Volontarily not a perfect conversion!
	const cupValueInG = cupValueInMl * d
	const cupValueInOz = cupValueInFlOz * d

	const tbspValueInMl = 15
	const tbspValueInFlOz = 0.5
	const tbspValueInG = tbspValueInMl * d
	const tbspValueInOz = tbspValueInFlOz * d

	const isFoodItem = food?.foodable?.type === 'foodItem'
	const isPackSize = (fpId) => isFoodItem && fpId === food?.foodPortion?.id
	const portions = food?.foodPortions
		?.map(({ id, name, value }) => ({
			id,
			isPackSize: isPackSize(id),
			name,
			value: +value,
		}))
		?.reverse()

	const findPortion = (id) => portions.find((fp) => fp.id === id)

	const handleMeasureChange = (v) => {
		setMeasure(v)

		const mSplit = v.split('-')
		if (v === 'proportion' && !isMass100) {
			setMass(inittialMass)
			setMassInOz(inittialMassInOz)
		} else if (v === 'cup') {
			const m = serving * cupValueInG
			const mInOz = serving * cupValueInOz
			setMass(m)
			setMassInOz(mInOz)

			if (density) {
				setVolume(cupValueInMl)
				setVolumeInFlOz(cupValueInFlOz)
			}
		} else if (v === 'tbsp') {
			const m = serving * tbspValueInG
			const mInOz = serving * tbspValueInOz
			setMass(m)
			setMassInOz(mInOz)

			if (density) {
				setVolume(tbspValueInMl)
				setVolumeInFlOz(tbspValueInFlOz)
			}
		} else if (mSplit[0] === 'portion') {
			const portionId = mSplit[1]
			const portion = findPortion(portionId)
			const m = serving * portion.value
			const mInOz = convertGToOz(m)
			setMass(m)
			setMassInOz(mInOz)
		}
	}

	const handleMassChange = (v) => {
		setMass(v)

		const mInOz = convertGToOz(v)
		setMassInOz(mInOz)
	}

	const handleMassInOzChange = (v) => {
		setMassInOz(v)

		const m = convertOzToG(v)
		setMass(m)
	}

	const handleVolumeChange = (v) => {
		setVolume(v)

		const vInFlOz = convertMlToFlOz(v)
		setVolumeInFlOz(vInFlOz)

		const m = v * density
		setMass(_.round(m, 1))
	}

	const handleVolumeInFlOzChange = (vInFlOz) => {
		setVolumeInFlOz(vInFlOz)

		const v = convertFlOzToMl(vInFlOz)
		setVolume(v)

		const m = v * density
		setMass(_.round(m, 1))
	}

	const handleServingChange = (v) => {
		setServing(v)

		let m = 0
		let mInOz = 0

		if (isCup) {
			m = v * cupValueInG
			mInOz = v * cupValueInOz
		} else if (isTbsp) {
			m = v * tbspValueInG
			mInOz = v * tbspValueInOz
		} else {
			const portionId = measureSplit[1]
			const portion = findPortion(portionId)
			m = v * portion.value
			mInOz = convertGToOz(m)
		}

		setMass(_.round(m, 1))
		setMassInOz(mInOz)
	}

	const [display, setDisplay] = useState('reduced')
	const isReduced = display === 'reduced'
	const toggleDisplay = () => (isReduced ? setDisplay('full') : setDisplay('reduced'))

	const {
		i18n: { language },
		t,
	} = useTranslation([
		'nutrition',
		'nutritionFacts',
		'dishes',
		'ncmComposition',
		'nutritionalComponents',
	])

	const nutritionalComposition = food?.nutritionalComposition

	const ncmcs = nutritionalComposition?.ncmCompositions
		?.filter(
			(ncmc) =>
				ncmc.variant !== 'from_target' &&
				(isReduced ? ncmc.nutritionalComponentMeasure.display === 'minimal' : true),
		)
		?.sort(
			(a, b) =>
				a.nutritionalComponentMeasure.nutritionalComponent.id -
				b.nutritionalComponentMeasure.nutritionalComponent.id,
		)

	const filterNCMCs = (category) =>
		ncmcs?.filter(
			(ncmc) =>
				_.camelCase(ncmc.nutritionalComponentMeasure.nutritionalComponent.category) === category,
		)

	const refNCMCs = () =>
		nutritionalComposition?.ncmCompositions
			?.filter((ncmc) => ncmc.variant === 'from_target')
			?.map((ncmc) => ({ key: ncmc.ncmComposition?.id, value: ncmc.value })) // Not sure about `?`...

	const findRefValue = (key) => refNCMCs()?.find((r) => r.key === key)?.value

	const { isSI } = useSelector((state) => state.measurement)

	const dataSource = (category) => {
		const items = filterNCMCs(category) || []
		const lookup = {}
		const rootItems = []

		items.forEach((ncmc) => {
			const floatValue = +ncmc.value
			const ncm = ncmc.nutritionalComponentMeasure
			const isEnergyDensityNcm = ['5', '7', '8'].includes(ncm.id)
			const systemMass = !isSI ? massInOz : mass
			const ncmcValue = isMass100
				? floatValue
				: (floatValue / 100) * (!isEnergyDensityNcm ? systemMass : mass)
			const value = ncmcValue.toFixed(ncmcValue >= 100 ? 0 : ncmcValue >= 10 ? 1 : 2)
			const refValue = findRefValue(ncmc.id)
			const floatRefValue = +refValue
			const pctRef =
				refValue && (isMass100 ? floatRefValue : (floatRefValue / 100) * mass).toFixed(1)

			const ncmcData = {
				key: ncm.id,
				component: ncm.description,
				value: decimalCommaFormatter(value, language),
				pctRef: decimalCommaFormatter(pctRef, language),
				children: undefined,
			}

			lookup[ncm.id] = ncmcData
		})

		items.forEach((ncmc) => {
			const ncmId = ncmc.nutritionalComponentMeasure.id

			let ncmParentId = ncmParentMap[+ncmId]?.toString()
			if ([680, 690].includes(+ncmId) && !ncmParentId) {
				ncmParentId = language.slice(0, 2) === 'fr' ? '675' : '674'
			}

			if (ncmParentId && lookup[ncmParentId]) {
				if (!lookup[ncmParentId].children) {
					lookup[ncmParentId].children = []
				}
				lookup[ncmParentId].children.push(lookup[ncmId])
			} else {
				rootItems.push(lookup[ncmId])
			}
		})

		return rootItems
	}

	return (
		<NutritionFacts
			measure={measure}
			isAmount={isAmount}
			showServing={showServing}
			handleMeasureChange={handleMeasureChange}
			mass={mass}
			handleMassChange={handleMassChange}
			massInOz={massInOz}
			handleMassInOzChange={handleMassInOzChange}
			volume={volume}
			handleVolumeChange={handleVolumeChange}
			volumeInFlOz={volumeInFlOz}
			handleVolumeInFlOzChange={handleVolumeInFlOzChange}
			serving={serving}
			handleServingChange={handleServingChange}
			isReduced={isReduced}
			toggleDisplay={toggleDisplay}
			density={density}
			food={food}
			portions={portions}
			dataSource={dataSource}
			referenceCitation={nutritionalComposition?.referenceCitation}
			isSI={isSI}
			t={t}
		/>
	)
}

export default NutritionFactsStatefulContainer

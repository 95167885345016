import _ from 'lodash'

import { supportedLocales } from '../contexts/i18n/i18nConfig'

export const prefixPath = (path, prefix) => {
	return `/${prefix}/${_.trim(path, '/')}`
}

export const getPathLocale = (path) => {
	if (path === '/' || path === '/callback') {
		return undefined
	}

	// Could use a more comprehensive Regex
	// (e.g. currently not including 'en_US')...
	const localeRegex = /^[a-z]{2}(-[A-Z]{2})?$/

	const potentialLocales = path.split('/')[1].match(localeRegex)

	if (potentialLocales === null) {
		return 'unknown'
	} else if (!supportedLocales.includes(potentialLocales[0])) {
		return 'unsupported'
	} else {
		return potentialLocales[0]
	}
}

const removePathLocale = (path) => {
	const urlObj = new URL(path, 'https://app.libra.diet')
	const { pathname, search, hash } = urlObj

	const pathSegments = pathname.split('/').filter((segment) => segment.length > 0)

	if (!pathSegments.length) {
		return `${pathname}${search}${hash}`
	} else {
		const newPathname = '/' + pathSegments.slice(1).join('/')
		return `${newPathname}${search}${hash}`
	}
}

export const replacePathLocale = (path, newLocale) => prefixPath(removePathLocale(path), newLocale)

export const l10nTo = (to, locale) => {
	let l10n = {}

	if (typeof to === 'string') {
		l10n = { pathname: prefixPath(to, locale) }
	} else if (typeof to === 'object') {
		const { pathname, ...rest } = to
		l10n = { pathname: prefixPath(pathname, locale), ...rest }
	}

	return l10n
}

export const getCountryCode = (locale) => {
	const fallbackMap = {
		fr: 'FR',
		en: 'US',
	}

	if (!locale) return null

	const parts = locale.split('-')

	return parts.length > 1 ? parts[1].toUpperCase() : fallbackMap[parts[0]] || null
}

export const ncmParentMap = {
	17: 2,
	18: 3,
	19: 4,
	20: 6,
	21: 7,
	22: 8,
	23: 10,
	24: 11,
	25: 12,
	26: 14,
	27: 15,
	28: 16,
	31: 29,
	33: 30,
	34: 29,
	35: 30,
	36: 29,
	39: 30,
	42: 36,
	43: 38,
	44: 39,
	45: 36,
	46: 38,
	47: 39,
	48: 36,
	49: 38,
	50: 39,
	51: 36,
	52: 1385,
	53: 1385,
	54: 38,
	57: 39,
	60: 1385,
	61: 1385,
	64: 40,
	65: 41,
	68: 36,
	69: 37,
	70: 38,
	71: 39,
	72: 40,
	73: 41,
	74: 36,
	75: 38,
	76: 39,
	77: 40,
	78: 41,
	79: 36,
	80: 79,
	81: 38,
	82: 39,
	83: 82,
	84: 40,
	85: 41,
	86: 29,
	87: 29,
	90: 30,
	91: 30,
	94: 86,
	95: 90,
	97: 29,
	98: 29,
	102: 30,
	103: 30,
	109: 29,
	110: 30,
	113: 29,
	114: 30,
	119: 115,
	120: 117,
	121: 115,
	122: 117,
	123: 115,
	124: 117,
	125: 115,
	126: 117,
	127: 115,
	128: 117,
	129: 115,
	130: 116,
	131: 117,
	132: 118,
	133: 115,
	134: 117,
	135: 131,
	136: 132,
	138: 115,
	139: 117,
	140: 131,
	141: 132,
	143: 115,
	144: 117,
	145: 131,
	146: 132,
	148: 115,
	149: 1258,
	151: 117,
	152: 115,
	153: 117,
	169: 164,
	170: 164,
	171: 166,
	172: 166,
	173: 168,
	174: 164,
	175: 164,
	176: 166,
	177: 166,
	178: 168,
	179: 164,
	180: 164,
	181: 166,
	182: 166,
	183: 168,
	196: 194,
	197: 195,
	198: 194,
	199: 195,
	200: 208,
	201: 209,
	206: 200,
	207: 201,
	208: 200,
	209: 201,
	210: 208,
	211: 209,
	212: 208,
	213: 209,
	248: 244,
	249: 245,
	250: 246,
	251: 247,
	252: 244,
	253: 245,
	254: 246,
	255: 247,
	264: 260,
	265: 261,
	266: 262,
	267: 263,
	268: 260,
	269: 261,
	270: 262,
	271: 263,
	300: 296,
	301: 297,
	302: 299,
	303: 296,
	304: 297,
	305: 299,
	306: 296,
	307: 297,
	308: 299,
	309: 296,
	310: 297,
	311: 299,
	312: 296,
	313: 297,
	314: 299,
	315: 296,
	316: 297,
	317: 299,
	318: 296,
	319: 297,
	320: 299,
	321: 296,
	322: 297,
	323: 299,
	324: 296,
	325: 297,
	326: 299,
	327: 296,
	328: 297,
	329: 299,
	330: 296,
	331: 297,
	332: 299,
	333: 296,
	334: 297,
	335: 299,
	336: 296,
	337: 297,
	338: 299,
	339: 296,
	340: 297,
	341: 299,
	342: 296,
	343: 297,
	344: 299,
	345: 296,
	346: 297,
	347: 299,
	348: 296,
	349: 297,
	350: 299,
	351: 296,
	352: 297,
	353: 299,
	354: 296,
	355: 297,
	356: 299,
	357: 296,
	358: 297,
	359: 299,
	360: 296,
	361: 297,
	362: 299,
	363: 296,
	364: 297,
	365: 299,
	366: 296,
	367: 297,
	368: 299,
	369: 296,
	370: 297,
	371: 299,
	378: 375,
	379: 376,
	380: 377,
	381: 375,
	382: 376,
	383: 377,
	384: 375,
	385: 376,
	386: 377,
	387: 375,
	388: 376,
	389: 377,
	390: 375,
	391: 376,
	392: 377,
	393: 375,
	394: 376,
	395: 377,
	396: 375,
	397: 376,
	398: 377,
	399: 375,
	400: 376,
	401: 377,
	402: 375,
	403: 376,
	404: 377,
	405: 375,
	406: 376,
	407: 377,
	408: 375,
	409: 376,
	410: 377,
	411: 375,
	412: 376,
	413: 377,
	414: 375,
	415: 376,
	416: 377,
	417: 375,
	418: 376,
	419: 377,
	420: 375,
	421: 376,
	422: 377,
	423: 375,
	424: 376,
	425: 377,
	426: 375,
	427: 376,
	428: 377,
	429: 375,
	430: 376,
	431: 377,
	432: 375,
	433: 376,
	434: 377,
	435: 375,
	436: 376,
	437: 377,
	438: 375,
	439: 376,
	440: 377,
	441: 375,
	442: 376,
	443: 377,
	444: 375,
	445: 376,
	446: 377,
	447: 375,
	448: 376,
	449: 377,
	450: 375,
	451: 376,
	452: 377,
	453: 375,
	454: 376,
	455: 377,
	462: 459,
	463: 460,
	464: 461,
	471: 456,
	472: 469,
	473: 458,
	474: 456,
	475: 469,
	476: 458,
	477: 456,
	478: 469,
	479: 458,
	480: 456,
	481: 469,
	482: 458,
	483: 456,
	484: 469,
	485: 458,
	486: 456,
	487: 469,
	488: 458,
	489: 456,
	490: 469,
	491: 458,
	492: 456,
	493: 469,
	494: 458,
	495: 456,
	496: 469,
	497: 458,
	498: 456,
	499: 496,
	502: 458,
	503: 456,
	504: 496,
	505: 458,
	506: 456,
	507: 469,
	508: 458,
	509: 456,
	510: 469,
	511: 458,
	512: 456,
	513: 469,
	514: 458,
	515: 456,
	516: 513,
	519: 458,
	520: 456,
	521: 513,
	522: 458,
	523: 456,
	524: 469,
	525: 458,
	526: 456,
	527: 469,
	528: 458,
	529: 456,
	530: 469,
	531: 458,
	532: 456,
	533: 469,
	534: 458,
	535: 456,
	536: 469,
	537: 458,
	538: 456,
	539: 536,
	540: 458,
	541: 456,
	542: 469,
	543: 458,
	544: 456,
	545: 469,
	546: 458,
	547: 456,
	548: 545,
	549: 458,
	550: 456,
	551: 545,
	552: 458,
	553: 456,
	554: 545,
	555: 458,
	556: 456,
	557: 469,
	558: 458,
	559: 456,
	560: 469,
	561: 458,
	562: 456,
	563: 560,
	564: 458,
	565: 456,
	566: 560,
	567: 458,
	568: 456,
	569: 469,
	570: 458,
	571: 456,
	572: 469,
	573: 458,
	574: 456,
	575: 572,
	576: 458,
	577: 456,
	578: 469,
	579: 458,
	580: 456,
	581: 469,
	582: 458,
	583: 456,
	584: 469,
	585: 458,
	586: 456,
	587: 469,
	588: 458,
	589: 456,
	590: 469,
	591: 458,
	592: 456,
	593: 469,
	594: 458,
	595: 456,
	596: 469,
	597: 458,
	598: 456,
	599: 469,
	600: 458,
	601: 456,
	602: 469,
	603: 458,
	604: 456,
	605: 602,
	606: 458,
	607: 456,
	608: 602,
	609: 458,
	610: 456,
	611: 469,
	612: 458,
	613: 456,
	614: 469,
	615: 458,
	616: 456,
	617: 614,
	618: 458,
	619: 456,
	620: 469,
	621: 458,
	622: 456,
	625: 458,
	626: 456,
	628: 458,
	629: 456,
	630: 627,
	631: 458,
	632: 456,
	633: 627,
	634: 458,
	635: 456,
	636: 627,
	637: 458,
	638: 456,
	639: 636,
	640: 458,
	641: 456,
	642: 636,
	643: 458,
	644: 456,
	645: 627,
	646: 458,
	647: 456,
	648: 627,
	649: 458,
	650: 456,
	652: 458,
	653: 456,
	654: 651,
	655: 458,
	656: 456,
	657: 651,
	658: 458,
	659: 456,
	660: 651,
	661: 458,
	662: 456,
	663: 651,
	664: 458,
	665: 456,
	666: 651,
	667: 458,
	668: 456,
	669: 651,
	670: 458,
	682: 680,
	683: 681,
	684: 680,
	685: 681,
	686: 680,
	687: 681,
	688: 680,
	689: 681,
	692: 690,
	693: 691,
	694: 1261,
	695: 1262,
	696: 692,
	697: 693,
	698: 692,
	699: 693,
	700: 690,
	701: 691,
	703: 1261,
	704: 1262,
	705: 690,
	706: 691,
	708: 1261,
	709: 1262,
	710: 690,
	711: 691,
	713: 1261,
	714: 1262,
	715: 690,
	716: 691,
	717: 690,
	718: 691,
	719: 717,
	720: 718,
	721: 717,
	722: 718,
	723: 690,
	724: 691,
	725: 723,
	726: 724,
	727: 690,
	728: 691,
	729: 690,
	730: 691,
	731: 690,
	732: 691,
	733: 690,
	734: 691,
	738: 735,
	739: 737,
	746: 743,
	747: 744,
	748: 745,
	749: 743,
	750: 745,
	754: 751,
	755: 753,
	756: 751,
	757: 753,
	758: 751,
	759: 753,
	760: 751,
	761: 753,
	762: 751,
	763: 753,
	778: 775,
	779: 777,
	781: 778,
	782: 779,
	783: 778,
	784: 779,
	785: 778,
	786: 779,
	787: 775,
	788: 777,
	792: 789,
	793: 791,
	798: 794,
	799: 796,
	800: 794,
	801: 796,
	806: 802,
	807: 803,
	808: 804,
	809: 805,
	810: 802,
	811: 804,
	812: 802,
	813: 803,
	814: 804,
	815: 802,
	816: 804,
	817: 802,
	818: 804,
	822: 819,
	823: 820,
	824: 821,
	826: 819,
	827: 821,
	828: 819,
	829: 821,
	831: 819,
	832: 821,
	834: 819,
	835: 821,
	837: 819,
	838: 821,
	839: 819,
	840: 821,
	841: 819,
	842: 821,
	843: 819,
	844: 821,
	845: 819,
	846: 821,
	850: 847,
	851: 849,
	852: 847,
	853: 849,
	854: 847,
	855: 849,
	861: 859,
	862: 860,
	863: 859,
	864: 860,
	865: 859,
	866: 860,
	867: 859,
	868: 860,
	869: 859,
	870: 860,
	871: 859,
	872: 860,
	873: 859,
	874: 860,
	875: 859,
	876: 860,
	877: 859,
	878: 860,
	879: 859,
	880: 860,
	916: 909,
	917: 910,
	918: 911,
	919: 912,
	920: 913,
	921: 914,
	922: 915,
	930: 923,
	931: 924,
	932: 925,
	933: 926,
	934: 927,
	935: 928,
	936: 929,
	969: 909,
	970: 910,
	971: 911,
	972: 914,
	973: 915,
	1004: 923,
	1005: 924,
	1006: 925,
	1007: 928,
	1008: 929,
	1206: 1204,
	1207: 1205,
	1210: 1208,
	1211: 1209,
	1214: 1212,
	1215: 1213,
	1220: 1219,
	1221: 1219,
	1222: 1221,
	1223: 1221,
	1224: 1221,
	1225: 1221,
	1226: 1221,
	1235: 1234,
	1236: 1234,
	1259: 40,
	1260: 41,
	1261: 1380,
	1262: 1380,
	1342: 674,
	1343: 675,
	1344: 677,
	1345: 678,
	1346: 1342,
	1347: 1343,
	1348: 1344,
	1349: 1345,
	1350: 690,
	1351: 1342,
	1352: 1343,
	1353: 691,
	1354: 1344,
	1355: 1345,
	1356: 690,
	1357: 1342,
	1358: 1343,
	1359: 691,
	1360: 1344,
	1361: 1345,
	1362: 690,
	1363: 1342,
	1364: 1343,
	1365: 691,
	1366: 1344,
	1367: 1345,
	1368: 773,
	1369: 776,
	1370: 819,
	1371: 821,
	1372: 819,
	1373: 821,
	1374: 819,
	1375: 821,
	1376: 819,
	1377: 821,
	1378: 819,
	1379: 821,
	1385: 29,
}

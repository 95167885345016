import styled from 'styled-components'

const Reader = styled.div`
	,
	> video {
		height: 240px;
	}

	overflow: hidden;

	> video {
		object-fit: cover;
	}

	> video,
	#qr-shaded-region {
		border-radius: 24px;
	}
`

export default Reader

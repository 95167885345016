import _ from 'lodash'

import { decimalCommaLocales } from '../contexts/i18n/i18nConfig'

const splitBase = (identifier) =>
	identifier
		.split('@')
		.shift()
		.split(/[.\s,]+/)
		.map((s) => _.upperFirst(s))

export const getFirstName = (identifier) => splitBase(identifier).shift()

export const getFullName = (identifier) => splitBase(identifier).join(' ')

export const getInitials = (identifier) =>
	splitBase(identifier)
		.map((n) => n.charAt(0))
		.join('')

export const mapToCamelCase = (array) => array.map((e) => _.camelCase(e))

export const joinWithQuotesAndAmpersand = (array) =>
	array
		.map((v) => `'${v}'`)
		.join(', ')
		.replace(/, ([^,]*)$/, ' & $1')

export const normalize = (string) =>
	string
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.toLowerCase()

export const decimalCommaFormatter = (number, locale) =>
	decimalCommaLocales.includes(locale) ? number?.toString()?.replace('.', ',') : number

export const decimalCommaParser = (number, locale) =>
	decimalCommaLocales.includes(locale) ? number?.toString()?.replace(',', '.') : number

export const chunkMarkdown = (markdownText) => {
	const lines = markdownText.split('\n')
	const chunks = []
	let currentChunk = []

	const isHeading = (line) => /^#{1,6}\s/.test(line)
	const isBoldLine = (line) => /^\*\*.*\*\*$/.test(line)
	// const isOrderedList = (line) => /^\s*\d+\.\s/.test(line)
	const isUnorderedList = (line) => /^\s*[-*+]\s/.test(line)
	// const isList = (line) => isOrderedList(line) || isUnorderedList(line)
	const endsWithColon = (line) => {
		const regex =
			/:_*\**\s*[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]*$/u

		return regex.test(line)
	}

	lines.forEach((line, index) => {
		const m1Line = lines[index - 1]
		const m1LineIsEmpty = !m1Line?.trim()
		const m2Line = lines[index - 2]
		const vsLine = !m1LineIsEmpty ? m1Line : m2Line
		const isFirst = index === 0
		const lineIsEmpty = !line.trim()
		const lineIsList = isUnorderedList(line)
		const vsLineIsHeading = vsLine && isHeading(vsLine)
		const vsLineIsBoldLine = vsLine && isBoldLine(vsLine.trim())
		const vsLineEndsWithColon = vsLine && endsWithColon(vsLine)

		if (
			!isFirst &&
			!lineIsEmpty &&
			!lineIsList &&
			!vsLineIsHeading &&
			!vsLineIsBoldLine &&
			!vsLineEndsWithColon
		) {
			chunks.push(currentChunk.join('\n'))
			currentChunk = []
		}

		currentChunk.push(line)
	})

	if (currentChunk.length) {
		chunks.push(currentChunk.join('\n'))
	}

	return chunks
}

const youTubeRegex = /https:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})/g

export const extractYouTubeIds = (text) => {
	const ids = []
	let match

	while ((match = youTubeRegex.exec(text)) !== null) {
		ids.push(match[1])
	}

	return ids
}

export const normalizeGtin = (gtin) => {
	if (!gtin) return null

	const cleaned = gtin.replace(/[\s-]/g, '')
	if (!/^\d+$/.test(cleaned)) return cleaned

	const PAD_LENGTHS = { 5: 6, 7: 8, 9: 12, 10: 12, 11: 12 }
	const len = cleaned.length

	if (PAD_LENGTHS[len]) {
		return cleaned.padStart(PAD_LENGTHS[len], '0')
	} else if (len === 14) {
		return cleaned.startsWith('0') ? cleaned.slice(1) : cleaned
	}

	return cleaned // Length 6, 8, 12, 13, or anything else remains unchanged.
}
